@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.");
@import url("https://use.fontawesome.com/releases/v5.13.0/css/all.css");
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,400;1,100&display=swap');
@import url('https://fonts.cdnfonts.com/css/proxima-nova-2');

html, body {touch-action: pan-x, pan-y;font-family: 'Proxima Nova', sans-serif; font-weight: 300;}

h1,h3{font-family: 'Proxima Nova', sans-serif; font-weight: 300;}


input[type="file"] {
width: 90px; /* Keep it under 100px in order to hide the unwanted text. */
}

.rpv-core__icon {
    fill: white!important;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1;
    text-align: center;
}

.rpv-core__minimal-button{
    color:white;
    fill: white;

}


.rpv-zoom__popover-target{
     color:white;
}


.rpv-zoom__popover-target-scale,.rpv-zoom__popover-target-scale--ltr{
 color:white;
}

.rpv-zoom__popover-target-arrow{
 color:white;
}

.glassmorphism{/* From https://css.glass */
background: rgba(255, 255, 255, 0.5);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(255, 255, 255, 0.3);}


.drag-img {
  /* cursor: move; */
  position: relative;
  width: 10%;
  top: 50%;
  transform: translate(-50%, -50%);
  left:50%;
}

.drag-img-mobile {
  /* cursor: move; */
  position: relative;
  width: 5%;
  top: 50%;
  transform: translate(-50%, -50%);
  left:50%;
}

.drag-container-pc {
  height: 500px;
  width: 500px;
}

.drag-container-mobile {
  /* height: 300px;
  width: 100%; */
}

.drag-container-inner {
  height: 5000px;
  width: 5000px;
  /* cursor: move; */
  position: relative;
  top: 50%;
  transform: translate(-50%, -50%);
  left:50%;
  

}

.cards-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 1.8em;
   
    /* background: red; */
}

.cards__container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 17rem;
     /* background-color: red!important; */
}

.box {
    /*     margin: -1.5rem; */
    /* width: 14rem; */
    width: 18rem;
    height: 20rem;
    /*box-shadow: 0px 0px 2rem 0px #888888;
    background-color: white;*/
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    /* transition: 1s all; */
}

.box:nth-child(2n) {
    transform: scale(0.85);
    z-index: -1;
}

.box:nth-child(2) {
    left: 5%;
    /* transform: scale(0.75); */
}

.box:nth-child(3) {
    left: -5%;
}

.box:nth-child(2n + 1) {
    transform: scale(0.75);
    z-index: -2;
}

.box:nth-child(1) {
    left: 15%;
}

.box:nth-child(5) {
    left: -15%;
}

.card__text-content {
    text-align: center;
    width: 75%;
}

.card__title {
    padding: 1rem;
}

.box--hide {
    display: none;
}

.move-out-from-left {
    animation: moveOutLeft 0.5s ease-in-out;
}

.move-out-from-right {
    animation: moveOutRight 0.5s ease-in-out;
}

.move-to-position5-from-left {
    animation: moveToP5Left 0.5s ease-in-out;
}

.move-to-position4-from-left {
    animation: moveToP4Left 0.5s ease-in-out;
}

.move-to-position3-from-left {
    animation: moveToP3Left 0.5s ease-in-out;
}

.move-to-position2-from-left {
    animation: moveToP2Left 0.5s ease-in-out;
}

.move-to-position1-from-left {
    animation: moveToP1Left 0.7s ease-in-out;
}

.move-to-position5-from-right {
    animation: moveToP5Right 0.7s ease-in-out;
}
.move-to-position4-from-right {
    animation: moveToP4Right 0.7s ease-in-out;
}
.move-to-position3-from-right {
    animation: moveToP3Right 0.7s ease-in-out;
}
.move-to-position2-from-right {
    animation: moveToP2Right 0.7s ease-in-out;
}
.move-to-position1-from-right {
    animation: moveToP1Right 0.7s ease-in-out;
}

@keyframes moveOutLeft {
    0% {
        transform: scale(0.75) translateX(0%);
        opacity: 1;
    }
    50% {
        transform: scale(0.5) translateX(-150%);
        opacity: 0.5;
    }
    100% {
        transform: scale(0.25) translateX(0%);
        opacity: 0;
    }
}

@keyframes moveOutRight {
    0% {
        transform: scale(0.75) translateX(0%);
        opacity: 1;
    }
    50% {
        transform: scale(0.5) translateX(150%);
        opacity: 0.5;
    }
    100% {
        transform: scale(0.25) translateX(0%);
        opacity: 0;
    }
}

@keyframes moveToP5Left {
    from {
        transform: scale(0.75) translateX(100%);
    }
    to {
        transform: scale(0.75) translateX(0);
    }
}

@keyframes moveToP4Left {
    from {
        transform: scale(0.75) translateX(100%);
    }
    to {
        transform: scale(0.85) translateX(0);
    }
}

@keyframes moveToP3Left {
    from {
        transform: scale(0.85) translateX(100%);
    }
    to {
        transform: scale(1) translateX(0);
        /* display: none; */
    }
}

@keyframes moveToP2Left {
    from {
        transform: scale(1) translateX(100%);
    }
    to {
        transform: scale(0.85) translateX(0);
    }
}

@keyframes moveToP1Left {
    from {
        transform: scale(0.85) translateX(100%);
    }
    to {
        transform: scale(0.75) translateX(0);
    }
}

@keyframes moveToP1Right {
    /* from {
        transform: scale(0.75) translateX(-100%);
    }
    to {
        transform: scale(0.75) translateX(0);
    } */
    0% {
        transform: scale(0.25) translateX(0%);
        opacity: 1;
    }
    50% {
        transform: scale(0.5) translateX(-150%);
        opacity: 0.5;
    }
    100% {
        transform: scale(0.75) translateX(0%);
        opacity: 0;
    }
}

@keyframes moveToP2Right {
    from {
        transform: scale(0.75) translateX(-100%);
    }
    to {
        transform: scale(0.85) translateX(0);
    }
}

@keyframes moveToP3Right {
    from {
        transform: scale(0.85) translateX(-100%);
    }
    to {
        transform: scale(0.75) translateX(0);
    }
}

@keyframes moveToP4Right {
    from {
        transform: scale(1) translateX(-100%);
    }
    to {
        transform: scale(0.85) translateX(0);
    }
}

@keyframes moveToP5Right {
    from {
        transform: scale(0.85) translateX(-100%);
    }
    to {
        transform: scale(0.75) translateX(0);
    }


    /* admin CSS*/
    
}


.box__text {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    color: white;
    text-align: center;
    padding-bottom: 1em;
    font-weight: bold;
    font-size: 18px;
}

.cards__container .box img {
    border-radius: 25px !important;
}

.box__learn__more {
    display: inline-flex;
    font-size: 14px;
    letter-spacing: 0px;
    font-weight: 600;
    line-height: 16px;
    text-decoration: none !important;
    text-transform: uppercase;
    background-color: #e9edf3;
    color: #535fd7 !important;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    justify-content: center;
    padding: 16px 32px;
    height: 48px;
    text-align: center;
    white-space: nowrap;
    color: #fff !important;
    background-color: #535fd7;
    border-radius: 5px;
}

.su-index-table-mobile-01 {
    display: none;
}

.box img {
    width: 100%;
    /* width: 1000px; */
    box-shadow: 0px 0px 2rem 0px #888888;
}

@media only screen and (max-width: 850px) {
    .cards__container {
        /*background: red;*/
        height: 10rem;
        overflow: hidden;
    }

    .cards__container {
        width: 57%;
        margin-right: 22px;
    }

    .box img {
        /* width: 100%; */
        /* box-shadow: 0px 0px 2rem 0px #888888; */
    }

    .box {
        /*     margin: -1.5rem; */
        /*width: 5rem!important;*/
        /* width: 230px; */
        /*background: red;*/
        box-shadow: none;
        
    }

    .box span {
        font-weight: normal;
        font-size: 14px;
    }

    .mobile-slide-right {
        position: relative;
    }

    .mobile-slide-nav {
        position: absolute;
        top: 0;
        color: #fff;
    }

    .mobile-slide-left {
        left: 2px;
        top: 47%;
    }

    .mobile-slide-right {
        right: -10px;
        top: 47%;
    }

    .su-index-table-desktop-01 {
        display: none;
    }

    .su-index-table-mobile-01 {
        display: block;
    }

    .su-index-table-mobile-table-01 {
        display: table !important;
        width: 90% !important;
    }

    table td {
        display: inline-block;
        width: 80% !important;
    }

    .su-index-table-mobile-width {
        width: 80% !important;
    }

    .su-index-table-mobile-width-2 {
        width: 65% !important;
        /* background: pink !important; */
    }
}

/*Flex*/
.d-flex {
    display: flex;
    justify-content: center;
}

.d-flex-justify-center {
    justify-content: center;
}

.d-flex-align-center {
    align-self: center;
}

/*Positioning*/
.position-rel {
    position: relative;
}

.swal-button--danger {
    background-color: green !important;
}

.swal-title {
    color: rgb(0 0 0);
}

/*homepage table*/
.su-index-table-01 {
    /* border: 1px solid black; */
}

.su-index-table-01 td {
    /* border: 1px solid black; */
    padding: 1em;
    margin: 0;
}

.su-index-table-01 td ul li {
    margin-top: 0.5em;
}


.my-bg-yellow-500{
    background-color: #f1c232!important;
   
}

.my-text-yellow-500{
    color: #f1c232!important;
   
}

.my-text-red-500{
    color: #f14400!important;
   
}

.my-bg-red-500{
    background-color: #f14400!important;
   
}

.my-text-green-500{
    color: #00f134!important;
   
}

.my-text-orange-500{
     color: rgb(251 146 60) !important;
}

.my-text-gray-500{
    color: #d3dbd5dc!important;
   
}

.my-bg-green-500{
    background-color: #00f134!important;
   
}

.my-hidden-elements{
    display: none;
}



.margin-center{  margin: 0 auto;}


body,html{overflow-x: hidden;}


.cards-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 1.8em;
    /* background: red; */
}

.cards__container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 17rem;
}

.box {
    /*     margin: -1.5rem; */
    /* width: 14rem; */
    width: 18rem;
    height: 20rem;
    /*box-shadow: 0px 0px 2rem 0px #888888;
    background-color: white;*/
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 10px;
    /* transition: 1s all; */
}

.box:nth-child(2n) {
    transform: scale(0.85);
    z-index: -1;
}



.box:nth-child(2) {
    left: 28%;
    z-index: 99999;
    /* transform: scale(0.75); */
}

.box:nth-child(3) {
    left: -30%;
}

.box:nth-child(2) {
    left:43px;
    right:0;
}

.box:nth-child(2n + 1) {
    transform: scale(0.75);
    z-index: -2;
}

.box:nth-child(1) {
    left: 35%;
}

.box:nth-child(5) {
    left: -15%;
}


.card__text-content {
    text-align: center;
    width: 75%;
}

.card__title {
    padding: 1rem;
}

.box--hide {
    display: none;
}

.move-out-from-left {
    animation: moveOutLeft 0.5s ease-in-out;
}

.move-out-from-right {
    animation: moveOutRight 0.5s ease-in-out;
}

.move-to-position5-from-left {
    animation: moveToP5Left 0.5s ease-in-out;
}

.move-to-position4-from-left {
    animation: moveToP4Left 0.5s ease-in-out;
}

.move-to-position3-from-left {
    animation: moveToP3Left 0.5s ease-in-out;
}

.move-to-position2-from-left {
    animation: moveToP2Left 0.5s ease-in-out;
}

.move-to-position1-from-left {
    animation: moveToP1Left 0.7s ease-in-out;
}

.move-to-position5-from-right {
    animation: moveToP5Right 0.7s ease-in-out;
}
.move-to-position4-from-right {
    animation: moveToP4Right 0.7s ease-in-out;
}
.move-to-position3-from-right {
    animation: moveToP3Right 0.7s ease-in-out;
}
.move-to-position2-from-right {
    animation: moveToP2Right 0.7s ease-in-out;
}
.move-to-position1-from-right {
    animation: moveToP1Right 0.7s ease-in-out;
}

@keyframes moveOutLeft {
    0% {
        transform: scale(0.75) translateX(0%);
        opacity: 1;
    }
    50% {
        transform: scale(0.5) translateX(-150%);
        opacity: 0.5;
    }
    100% {
        transform: scale(0.25) translateX(0%);
        opacity: 0;
    }
}

@keyframes moveOutRight {
    0% {
        transform: scale(0.75) translateX(0%);
        opacity: 1;
    }
    50% {
        transform: scale(0.5) translateX(150%);
        opacity: 0.5;
    }
    100% {
        transform: scale(0.25) translateX(0%);
        opacity: 0;
    }
}

@keyframes moveToP5Left {
    from {
        transform: scale(0.75) translateX(100%);
    }
    to {
        transform: scale(0.75) translateX(0);
    }
}

@keyframes moveToP4Left {
    from {
        transform: scale(0.75) translateX(100%);
    }
    to {
        transform: scale(0.85) translateX(0);
    }
}

@keyframes moveToP3Left {
    from {
        transform: scale(0.85) translateX(100%);
    }
    to {
        transform: scale(1) translateX(0);
        /* display: none; */
    }
}

@keyframes moveToP2Left {
    from {
        transform: scale(1) translateX(100%);
    }
    to {
        transform: scale(0.85) translateX(0);
    }
}

@keyframes moveToP1Left {
    from {
        transform: scale(0.85) translateX(100%);
    }
    to {
        transform: scale(0.75) translateX(0);
    }
}

@keyframes moveToP1Right {
    /* from {
        transform: scale(0.75) translateX(-100%);
    }
    to {
        transform: scale(0.75) translateX(0);
    } */
    0% {
        transform: scale(0.25) translateX(0%);
        opacity: 1;
    }
    50% {
        transform: scale(0.5) translateX(-150%);
        opacity: 0.5;
    }
    100% {
        transform: scale(0.75) translateX(0%);
        opacity: 0;
    }
}

@keyframes moveToP2Right {
    from {
        transform: scale(0.75) translateX(-100%);
    }
    to {
        transform: scale(0.85) translateX(0);
    }
}

@keyframes moveToP3Right {
    from {
        transform: scale(0.85) translateX(-100%);
    }
    to {
        transform: scale(0.75) translateX(0);
    }
}

@keyframes moveToP4Right {
    from {
        transform: scale(1) translateX(-100%);
    }
    to {
        transform: scale(0.85) translateX(0);
    }
}

@keyframes moveToP5Right {
    from {
        transform: scale(0.85) translateX(-100%);
    }
    to {
        transform: scale(0.75) translateX(0);
    }
}




@media only screen and (max-width: 850px) {
  
.box {
    width: 8.8rem!important;
    height: 10.8rem!important;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 10px;
   
}

.box:nth-child(2n) {
    transform: scale(0.85);
    z-index: -1;
}

.box:nth-child(2) {
    left: 2%;
     transform: scale(0.85);
     
    /* transform: scale(0.75); */
}
/* third div */
.box:nth-child(3) {
    left: -15%;
     width: 120px !important;
    height: 57% !important;
}

.box:nth-child(2n + 1) {
    transform: scale(1);
    z-index: -2;
}

/* first div */
.box:nth-child(1) {
    left: 21%;
     width: 120px !important;
    height: 57% !important;
}

.box:nth-child(5) {
    left: -15%;
}

.cards__container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10rem;
    padding-top: -10px!important;
    /* background-color: red; */
    
}
 .my-xxsm{font-size:  .6rem;}
 .my-xsm{font-size:  .76rem;}
 .my-sm{font-size:  0.8rem;}
       .my-base{font-size:  1rem;}
       .my-xl{font-size:  1.25rem;}
       .my-2xl{font-size:  1.563rem;}
       .my-3xl{font-size:  1.953rem;}
       .my-4xl{font-size:  2.441rem;}
       .my-5xl{font-size:  3.052rem;}
    

}

.z-9{
    z-index: 999!important;
}
.z-99{
    z-index: 9999!important;
}
.z-999{
    z-index: 99999!important;
}
.z-9999{
    z-index: 999999!important;
}

.z-99999{
    z-index: 9999999!important;
}

.z-999999{
    z-index: 99999999!important;
}

.react-pdf__Page__textContent{
    display: none!important;
}


.hrDiv{

width: 100%;
border-left: none;
border-right: none;
/* border-bottom: .3px solid #000000;  */
box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
border-bottom:1px solid #e9edf3;
}


.custom-page-content ul {
    list-style-type: circle!important;
    margin-left: 1em!important;
}

.custom-page-content ul ul {
	list-style-type: circle!important;
}
.custom-page-content ul ul ul {
	list-style-type: circle!important;
}

.custom-page-content ol {
	/* list-style-type: upper-roman!important; */
    list-style-type: decimal!important;
    margin-left: 1em!important;
}

.custom-page-content ol ol {
    list-style-type: lower-alpha!important;
    
}

.custom-page-content ol ol ol {
    list-style-type: lower-alpha!important;
    
}

.custom-page-content ol ol li,.custom-page-content ul ul li{
	margin-left: 1em;
}

.custom-page-content b,strong {font-weight: bold!important; font-size: 16px!important; }

.custom-page-content i {
    color: black!important;
}

/* .custom-page-content a {
    text-decoration: underline!important; color:blue;
} */

/* .faq-page-c0{} */
.faq-page-c2{font-weight: bold; font-size: 18px;}
.faq-page-c5{font-weight: bold; font-size: 16px;}
.faq-page-c3{font-weight: normal; opacity: .8; margin-bottom:1em; display: block;}


.payment-terms-c0{margin-bottom: 1em!important;}
.payment-terms-c2{font-weight: bold; font-size: 18px;}
.payment-terms-c5{font-weight: bold; font-size: 16px;}
.payment-terms-c1{font-weight: normal; opacity: .8; margin-bottom:1em; display: block; }

/* .terms-condition-c0{} */
.terms-condition-c3{font-weight: bold; font-size: 18px;}
.terms-condition-c5{font-weight: bold; font-size: 16px;}
.terms-condition-c1{font-weight: normal; opacity: .8; margin-bottom:1em; display: block; }

/* .terms-condition-c0{} */
.privacy-policy-c4{font-weight: bold; font-size: 18px;}
.privacy-policy-c5{font-weight: bold; font-size: 16px;}
.privacy-policy-c0{font-weight: normal; opacity: .8; margin-bottom:1em; display: block; }


.draft-8-px strong{ font-size:8px!important;}
.draft-9-px strong{ font-size:9px!important;}
.draft-10-px strong{ font-size:10px!important;}
.draft-12-px strong{ font-size:12px!important;}
.draft-14-px strong{ font-size:14px!important;}
.draft-16-px strong{ font-size:16px!important;}
.draft-18-px strong{ font-size:18px!important;}
.draft-20-px strong{ font-size:20px!important;}
.draft-22-px strong{ font-size:22px!important;}
.draft-24-px strong{ font-size:24px!important;}
.draft-26-px strong{ font-size:26px!important;}
.draft-28-px strong{ font-size:28px!important;}
.draft-30-px strong{ font-size:30px!important;}
.draft-32-px strong{ font-size:32px!important;}
.draft-34-px strong{ font-size:34px!important;}
.draft-37-px strong{ font-size:37px!important;}
.draft-38-px strong{ font-size:38px!important;}
.draft-40-px strong{ font-size:40px!important;}
.draft-42-px strong{ font-size:42px!important;}
.draft-44-px strong{ font-size:44px!important;}
.draft-46-px strong{ font-size:46px!important;} 
.draft-48-px strong{ font-size:48px!important;}
.draft-50-px strong{ font-size:50px!important;}
.draft-52-px strong{ font-size:52px!important;}
.draft-54-px strong{ font-size:54px!important;}
.draft-56-px strong{ font-size:56px!important;}
.draft-58-px strong{ font-size:58px!important;}
.draft-60-px strong{ font-size:60px!important;}
.draft-62-px strong{ font-size:62px!important;}
.draft-64-px strong{ font-size:64px!important;}
.draft-66-px strong{ font-size:66px!important;}
.draft-68-px strong{ font-size:68px!important;}
.draft-70-px strong{ font-size:70px!important;}
.draft-72-px strong{ font-size:72px!important;}
.draft-74-px strong{ font-size:74px!important;}
.draft-76-px strong{ font-size:76px!important;}
.draft-78-px strong{ font-size:78px!important;}
.draft-80-px strong{ font-size:80px!important;}
.draft-82-px strong{ font-size:82px!important;}
.draft-84-px strong{ font-size:84px!important;}
.draft-88-px strong{ font-size:88px!important;}
.draft-90-px strong{ font-size:90px!important;}
.draft-92-px strong{ font-size:92px!important;}
.draft-94-px strong{ font-size:94px!important;}
.draft-96-px strong{ font-size:96px!important;}
.draft-98-px strong{ font-size:98px!important;}
.draft-100-px strong{ font-size:100px!important;}

.disable-text-selection {
        -webkit-user-select: none;
        -webkit-touch-callout: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
       
      }

/* Stacked Card*/
 .stacked-card {
  background-color: var(--background);
  display: block;
  width: 200px;
  cursor: pointer;
  padding: 0px;
  margin: calc(4vh - 30px) auto 0 auto;
  margin-bottom: 1em;;
  border: 2px solid var(--grey);
  box-shadow: 
  3px 3px 0 -2px var(--background), 3px 3px var(--grey),
        6px 6px 0 -2px var(--background), 6px 6px var(--grey),
        9px 9px 0 -2px var(--background), 9px 9px var(--grey),
        12px 12px 0 -2px var(--background), 12px 12px var(--grey),
        15px 15px 0 -2px var(--background), 15px 15px var(--grey);

}
@media all and (max-width: 520px) {
   .stacked-card {
     width: 120px;
  /* animation: shadow-wave 1s ease infinite; */
}
 
}


@keyframes shadow-wave {
  0% {
    border: 3px solid var(--grey);
    box-shadow: 10px -10px 0 -3px var(--background), 10px -10px var(--grey),
      20px -20px 0 -3px var(--background), 20px -20px var(--grey),
      30px -30px 0 -3px var(--background), 30px -30px var(--grey),
      40px -40px 0 -3px var(--background), 40px -40px var(--grey);
  }
  20% {
    border: 3px solid var(--grey);
    box-shadow: 10px -10px 0 -3px var(--background), 10px -10px var(--grey),
      20px -20px 0 -3px var(--background), 20px -20px var(--grey),
      30px -30px 0 -3px var(--background), 30px -30px var(--grey),
      40px -40px 0 -3px var(--background), 40px -40px var(--grey);
  }
  40% {
    border: 3px solid var(--grey);
    box-shadow: 10px -10px 0 -3px var(--background), 10px -10px var(--grey),
      20px -20px 0 -3px var(--background), 20px -20px var(--grey),
      30px -30px 0 -3px var(--background), 30px -30px var(--grey),
      40px -40px 0 -3px var(--background), 40px -40px var(--yellow);
  }
  60% {
    border: 3px solid var(--grey);
    box-shadow: 10px -10px 0 -3px var(--background), 10px -10px var(--grey),
      20px -20px 0 -3px var(--background), 20px -20px var(--grey),
      30px -30px 0 -3px var(--background), 30px -30px var(--grey),
      40px -40px 0 -3px var(--background), 40px -40px var(--grey);
  }
  80% {
    border: 3px solid var(--grey);
    box-shadow: 10px -10px 0 -3px var(--background), 10px -10px var(--grey),
      20px -20px 0 -3px var(--background), 20px -20px var(--grey),
      30px -30px 0 -3px var(--background), 30px -30px var(--grey),
      40px -40px 0 -3px var(--background), 40px -40px var(--grey);
  }
  100% {
    border: 3px solid var(--grey);
    box-shadow: 10px -10px 0 -3px var(--background), 10px -10px var(--grey),
      20px -20px 0 -3px var(--background), 20px -20px var(--grey),
      30px -30px 0 -3px var(--background), 30px -30px var(--grey),
      40px -40px 0 -3px var(--background), 40px -40px var(--grey);
  }
  
}


:root {
  --primary: #22D2A0;
  --secondary: #192824;
  --background: #192824;
  --green: #1FC11B;
  --yellow: #FFD913;
  --orange: #FF9C55;
  --red: #FF5555;
   --grey: #FEFEFEFE;
} 

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/* body {
  background-image: radial-gradient(var(--secondary) 30%, var(--background) 30%);
  background-size: 2px 3px;
  font-family: "Archivo", sans-serif;
  color: var(--primary);
} */

.stacked-card p {
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-bottom: 10px;
}

.stacked-card h2 {
  font-size: 14px;
  font-family: "Archivo Black", "Archivo", sans-serif;
  font-weight: normal;
}
     

.bg-custom-page-link-button{
    background: #000000!important;
}

    

.landing-page-search::placeholder {
    font: .7rem sans-serif;
}

/*Supply Identity Searcheable List */
.my-identity.dropdown {
  position: relative;
  color: #333;
  cursor: default;
}

.my-identity.dropdown .arrow {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: " ";
  display: block;
  height: 0;
  margin-top: 0.3rem;
  position: absolute;
  right: 10px;
  top: 14px;
  width: 0;
}

.my-identity.dropdown .arrow.open {
  border-color: transparent transparent #999;
  border-width: 0 5px 5px;
}

.my-identity.dropdown .selected-value input {
  line-height: 1.5;
  font-size: 1rem;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  box-sizing: border-box;
  cursor: default;
  outline: none;
  padding: 8px 52px 8px 10px;
  transition: all 200ms ease;
  width: 100%;
}

.my-identity.dropdown .options {
  display: none;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
}

.my-identity.dropdown .options.open {
  display: block;
}

.my-identity.dropdown .option {
  box-sizing: border-box;
  color: rgba(51, 51, 51, 0.8);
  cursor: pointer;
  display: block;
  padding: 8px 10px;
}

.my-identity.dropdown .option.selected,
.my-identity.dropdown .option:hover {
  background-color: #f2f9fc;
  color: #333;
}

/*Google Places Autocomplete*/
.pac-container {
 background-color: #151515;
 font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
  Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.pac-item,
.pac-item-query {
 color: #f7f7f7;
}
.pac-item:hover {
 background: #6666;
}

/*Facia Verificatioin*/
#video {
  width: 100%;
  height: auto;
  position: absolute;
  top: 350px;
  left: 450px;
}

#canvas {
  position: absolute;
  top: 350px;
  left: 450px;
  z-index: 10;
}

#video-container {
  margin: 0 auto;
  margin-top: 50px;
  position: center;
  justify-content: center;
}

.bottom-triangle{
     position: absolute;
      bottom: 0px;
      /* transform: translateX(-50%); */
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-top: 20px solid #000000; /* Change the color as needed */
      z-index: 99999999;
}

.circle-icon {
      padding: 10px;
      border-radius: 50%;
      border-color: #fff;
      border-width: 1px;
    }

/*Google Places Component*/
/* body .css-psaiwf-Input{
    width:100%;
    display:none;
} */

/* #react-select-2-input{
    display: none!important;
} */
/* .google-places-control-wrapper div{
    width: 250px!important;
   
} */

.google-autocomoplete-supplier-form .css-1hd9ag4-Input{width: 200px;}


/*Customer Services*/
.react-input-customer-service .form-control {
   width: 100%!important;
}


/*Banner slider*/
.awssld__controls__arrow-right::before{
background-color: white!important;
}

.awssld__controls__arrow-right::after{
background-color: white!important;
}

.awssld__controls__arrow-left::before{
background-color: white!important;
}

.awssld__controls__arrow-left::after{
background-color: white!important;
}

@media all and (max-width: 520px) {
	 __arrow-left {
		 height: 20px !important;
		 margin-left: 20px;
         visibility: visible;
	}
	 __arrow-right {
		 height: 20px !important;
		 margin-right: 20px;
          visibility: visible;
	}

     .awssld__controls__arrow-right::before{
  visibility: visible!important;
}

.awssld__controls__arrow-right::after{
  visibility: visible!important;
}

.awssld__controls__arrow-left::before{
  visibility: visible!important;
}

.awssld__controls__arrow-left::after{
  visibility: visible!important;
}
  
}
 


@import "react-image-gallery/styles/css/image-gallery.css";
