.align-right div {
  text-align: right;
}
.align-center div {
  text-align: center;
}
.align-left div {
  text-align: left;
}


.font-size-dropdown {
    display: inline-block;
}

.font-size-menu {
    display: inline-block;
    position: absolute;
    top: 3px;
    background: #FFFFFF;
    box-shadow: 0 0 2px;
    max-height: 150px;
    overflow-y: scroll;
}

.font-size-menu::-webkit-scrollbar {
    display: none
}

.font-size-option {
    padding: 3px;
}

.font-size-option:hover {
    background: #F0F0F0;
    cursor: pointer;
}

#tooltip {
    position:absolute;
    display:none;
    background:white;
}
#tooltip-edit {
    position:absolute;
    display:none;
    background:white;
}

#info {
    padding-left: 10px;
    border-radius: 10px;
    position: absolute;
    user-select: none;
    font-size: 2em;
    width: 120px;
    color: #EEEEEE;
    background-color: #FD7013;
}

#cal1{
    position:absolute;
    height:0px;
    width:0px;
    top:100px;
    left:100px;
    overflow:none;
    z-index:-100;
}
#cal2{
    position:absolute;
    height:0px;
    width:0px;
    top:0px;
    left:0px;
    overflow:none;
    z-index:-100;
}

.extra-shadow{
    box-shadow: 0 0 0 1px rgb(0 0 0 / 5%), 0 4px 20px rgb(0 0 0 / 15%);
}

.DraftEditor-editorContainer .public-DraftEditor-content a, .custom-page-content a{color:blue; text-decoration: underline;}

.DraftEditor-editorContainer .public-DraftEditor-content h1,h2,h3,h4,h5{margin: 0 0 1em 0!important ;}

.custom-page-content h1,h2,h3,h4,h5{margin: 0 0 1em 0!important ;}

.DraftEditor-editorContainer .public-DraftEditor-content div:not(:first-child){
   margin: 1em 0 1em 0!important ;
}

.custom-page-content p{ margin: 1em 0 1em 0!important ;}
.DraftEditor-editorContainer .public-DraftEditor-content h1, .custom-page-content h1{
font-size:  2em!important;
font-weight: bolder!important;
}

.DraftEditor-editorContainer .public-DraftEditor-content h2, .custom-page-content h2{
    font-weight: bolder!important;
font-size: 1.5em!important;
}

.DraftEditor-editorContainer .public-DraftEditor-content h3, .custom-page-content h3{
font-weight: bolder!important;	
font-size: 1.7em!important;
    
}

.DraftEditor-editorContainer .public-DraftEditor-content h4, .custom-page-content h4{
font-size: 1em!important;
font-weight: bolder!important;
}

.DraftEditor-editorContainer .public-DraftEditor-content h5, .custom-page-content h5{    	
font-size: .83em!important;
font-weight: bolder!important;
}

.DraftEditor-editorContainer .public-DraftEditor-content h6{    	
font-size: .67em!important;
font-weight: bolder!important;
}

.DraftEditor-editorContainer .public-DraftEditor-content ol  li:first-of-type {
     margin-left: 20px;
}

.DraftEditor-editorContainer .public-DraftEditor-content ol  {
  list-style-type: decimal!important; 
  
}

.DraftEditor-editorContainer  ul ul {
  list-style-type: circle!important; 
  
}

.DraftEditor-editorContainer ul ul ul{
  list-style-type: circle!important; 
  
}

.DraftEditor-editorContainer .public-DraftEditor-content img {
    width: 100% !important;
}


.shrink-stuff{
-webkit-transform:scale(0.7)!important;
-moz-transform:scale(0.7)!important;
-ms-transform:scale(0.7)!important;
transform:scale(0.7)!important;
width:100%
}


