

.title {
  width: 25%;
  height: 100%;
  display: flex;
  align-items: center;
}

.title h2 {
  margin: auto;
}

.tools button span:hover {
  color: #99cccc;
}

.calendar-body {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.table-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.weekday {
  width: 100px;
  text-align: center;
}

.calendar-day {
  border: 1px solid #a6a6a6;
}

.calendar-day:hover {
  background-color: #dfdbdb;
}

.selected p {
  color: #cc0000;
  font-weight: bold;
}

.shrink-calendar{
-webkit-transform:scale(0.8)!important;
-moz-transform:scale(0.8)!important;
-ms-transform:scale(0.8)!important;
transform:scale(0.8)!important;
width:70%
}